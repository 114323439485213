html,body {
  height: 100%;
}
body {
  display:flex;
  flex-direction:column;
}
.wrap {
  position: relative;
  margin: 0 auto;
  padding: 0 14px;
  max-width: 1304px;
  width: 100%;
}

.content {
  &.second {
    background-color: $white;
  }
}

.top-menu-block {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: $white;
  box-shadow: 0 0 3px 0 #5a5a5a;
  transform: translate(0, -95px);
  transition: transform 250ms linear;
  z-index: 3;
  &.active {
    transform: translate(0, 0px);
  }
  .menu {
    position: relative;
    top: 0;
    display: flex;
    list-style: none;
    padding: 31px 0;
    font-weight: 500;
    a {
      margin-left: 20px;
      position: relative;
      display: block;
      color: #404e6c;
      font-weight: normal;
      white-space: nowrap;
      font-size: 18px;
      text-decoration: none;
      &:before {
        content: '';
        display: none;
        position: absolute;
        bottom: -31px;
        height: 3px;
        width: 100%;
        background-color: #47b8ff;
      }
      &:hover {
        color: #47b8ff;
        &:before {
          content: '';
          display: block;
        }
      }
    }
  }
  .logo {
    margin: 14px 0 20px;
    text-decoration: none;
    img {
      display: inline-block;
      vertical-align: top;
      width: 40px;
      margin-right: 15px;
    }
    .text {
      margin-top: 15px;
      display: inline-block;
      vertical-align: top;
      font-size: 18px;
      color: #47b8ff;
      text-decoration: none;
    }
  }
  .flex {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
  }
}

.products-wrap {
  padding: 2.6vw 0 65px;
  font-size: 1.6vw;
  font-weight: bold;
  line-height: normal;
  color: $black;
  .title {
    margin-bottom: 2.7vw;
  }
  .products-list {
    display: flex;
    flex-flow: row wrap;
    margin-left: -2%;
    .product {
      position: relative;
      width: 31.3%;
      margin: 0 0 2.7vw 2%;
      a {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
      }
      .img-block {
        margin-bottom: 1.6vw;
        @include aspect-ratio(16, 16, 'inner-block');
        .inner-block {
          &:before {
            content: '';
            position: absolute;
            display: block;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: linear-gradient(223.7deg, rgba(0, 0, 0, 0.0001) 2.97%, rgba(101, 101, 101, 0.167827) 92.21%);
          }
          img {
            display: block;
            width: 100%;
            height: 100%;
          }
        }
      }
      &:hover {
        .img-block .inner-block:before {
          display: none;
        }
      }
    }
  }
}

.services-wrap {
  padding: 2.6vw 0 0;
  color: $black;
  .title {
    font-size: 1.6vw;
    font-weight: bold;
    line-height: normal;
    margin-bottom: 2.1vw;
  }
  .directions-wrap {
    .title {
      margin-bottom: 1.7vw;
      font-size: 1.15vw;
    }
    .direction-list {
      margin-left: -40px;
      padding-bottom: 6px;
      display: flex;
      flex-flow: row wrap;
      .direction {
        margin: 0 0 40px 40px;
        padding: 14px 25px 12px;
        font-size: 18px;
        font-weight: bold;
        line-height: 26px;
        text-transform: uppercase;
        color: #373737;
        box-shadow: inset 0 0 0 3px #47b8ff;
        border-radius: 11px;
      }
    }
  }
  .solutions-wrap {
    padding-top: 50px;
    background-color: #f9f9f9;
    .title {
      margin-bottom: 30px;
      font-size: 1.15vw;
    }
    .solutions-list {

    }
    .solution {
      padding-bottom: 60px;
      display: flex;
      flex-flow: row nowrap;
      align-items: flex-start;
      .img-block {
        margin-right: 40px;
        width: 260px;
        flex-shrink: 0;
        @include aspect-ratio(53,43, 'inner-block');
        .inner-block {
          background: center / contain no-repeat;
        }
      }
      .info-block {
        max-width: 660px;
        .title {
          font-size: 16px;
          font-weight: bold;
          color: #000;
          margin-bottom: 6px;
        }
        .description {
          margin-bottom: 20px;
          font-size: 14px;
          line-height: 20px;
          p {
            margin-top: 6px;
          }
        }
        .btn {
          padding: 17px 25px;
          font-size: 18px;
          line-height: normal;
          height: 54px;
          border-radius: 30px;
        }
      }
    }
  }
}

.project-bg-block {
  position: absolute;
  top: 0;
  width: 100%;
  height: 660px;
  background-image: linear-gradient(to bottom, rgba(71, 184, 255, 0.6), rgba(71, 184, 255, 0.6) 67%, rgba(71, 184, 255, 0));
  overflow: hidden;
  .ellipse {
    position: absolute;
    top: -70px;
    right: -30px;
    background: url(../img/ellipse.png) right top no-repeat;
    width: 577px;
    height: 577px;
  }

}
.project-wrap {

}
.project-top {
  margin-bottom: 275px;
  padding-top: 72px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  .info {
    max-width: 556px;
    flex-grow: 1;
  }
  .title {
    font-size: 36px;
    font-weight: bold;
    color: $white;
    text-transform: uppercase;
  }
  .text-block {
    font-size: 18px;
    color: $white;
    p {
      margin-top: 13px;
      line-height: 21px;
    }
  }
  .img-block {
    flex-shrink: 0;
  }
  .link {
    color: #24272d;
    font-size: 18px;
    text-transform: uppercase;
  }
}

.project-main-info {
  margin-bottom: 50px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  .title {
    font-size: 24px;
    font-weight: bold;
    padding-bottom: 60px;
  }
  .left {
    flex-grow: 1;
  }
  .right {
    margin-left: 90px;
    padding: 0 0 5px 39px;
    border-left: 1px solid #ebebeb;
    width: 395px;
    flex-shrink: 0;
  }
  .text-block {
    font-size: 18px;
    color: #000;
    p {
      margin-top: 0;
    }
  }
  .group-block {
    margin-bottom: 25px;
    .position {
      margin-bottom: 18px;
      font-size: 18px;
      font-weight: bold;
    }
    .name {
      margin-bottom: 19px;
      font-size: 16px;
    }
  }
}

.project-slider-wrap {
  .title {
    margin-bottom: 30px;
    font-size: 36px;
    color: #4a4a4a;
    font-weight: bold;
  }
  .carousel {
    margin-bottom: 60px;
  }
  .img-list {
    display: flex;
    align-items: flex-start;
    img {
      display: block;
      width: 31%;
      margin-right: 3%;
      &:last-child {
        margin-right: 0;
      }
    }
  }
  .carousel-inner {
    margin: 0 auto;
    width: calc(100% - 116px);
    overflow: hidden;
  }
  .carousel-indicators {
    display: none;
    margin: 10px 0 0;
    position: relative;
    bottom: inherit;
    li {
      background-color: #9b9b9b;
      &.active {
        background-color: #698cc8;
      }
    }
  }
  .carousel-control-next, .carousel-control-prev {
    width: 58px;
    color: #c8c8c8;
    opacity: 1;
    .icon {
      font-size: 58px;
    }
    &:hover {
      color: #698cc8;
    }
  }
}

.title {
  font-size: 36px;
  font-weight: bold;
  color: $black;
  text-transform: uppercase;  
}
.text-block {
  font-size: 18px;
  color: $black;
}