header {
  position: relative;
  z-index: 1;
  .logo {
    display: inline-block;
    text-decoration: none;
    img {
      display: inline-block;
      vertical-align: top;
      width: 75px;
      max-width: 100%;
      margin-right: 18px;
    }
    .text {
      margin-top: 19px;
      display: inline-block;
      vertical-align: top;
      font-size: 24px;
      line-height: 28px;
      letter-spacing: -0.5px;
      color: $white;
      text-decoration: none;
    }
    &.main {
      position: absolute;
      top: 30px;
      left: 14px;
      z-index: 1;
    }
  }
  .burger {
    position: absolute;
    top: 38px;
    right: 14px;
    z-index: 10;
    img {
      display: block;
      max-width: 100%;
    }
  }

  &.main {
    background-color: transparent;
  }
  .carousel-item {
    height: 610px;
    .img-block {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-position: center top;
      background-size: auto 100%;
      background-color: #99d8ff;
      background-blend-mode: lighten;
      background-repeat: no-repeat;
      &.last {
        background-blend-mode: inherit;

      }
      &:after {
        content:'';
        position: absolute;
        left: 0;
        bottom: 56px;
        width: 100%;
        height: 220px;
        background: linear-gradient(to bottom, transparent 0%, #fafafa 100%);
      }
      .wrap {
        z-index: 1;
      }
    }
    .title-block {
      padding: 190px 0 30px;
      display: flex;
      justify-content: space-between;
      .title {
        max-width: 680px;
        text-transform: uppercase;
        color: $black;
        font-size: 48px;
        font-weight: 900;
        line-height: 38px;
      }
      .logo img {
        width: auto;
      }
    }
    .text-block {
      font-size: 18px;
      font-weight: 800;
      line-height: normal;
      .btn {

      }
      .site-link {
        margin: 11px 0 0 30px;
        display: inline-block;
        vertical-align: top;
        font-size: 18px;
        text-transform: uppercase;
        font-weight: normal;
      }
    }
  }
  .carousel-indicators {
    bottom: 75px;
    li {
      width: 14px;
      height: 14px;
      background-color: gray;
      border-radius: 50%;
      &.active {
        background-color: $blue;
      }
    }
  }
}