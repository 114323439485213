@media (max-width: 920px) {
  .product-block .product-list .product-info .text {
    font-size: 14px;
  }
}

@media (max-width: 840px) {
  .top-menu-block {
    .logo {
      .text {
        display: none;
      }
    }
  }
}

@media (max-width: 600px) {
  .top-menu-block {
    transform: translateY(-260px);
    .logo {
      display: none;
    }
    .flex {
      display: block;
    }
    .menu {
      display: block;
      padding: 10px 0;
      text-align: center;
      a {
        padding: 10px 0;
        display: block;
      }
    }
  }
}
@media (max-width: 820px) {
  .wrap {
    padding: 0 10px;
  }
  header {
    .logo {
      width: 36px;
      .text {
        display: none;
      }
    }
    .burger {
      position: absolute;
      top: 32px;
      right: 10px;
      width: 38px;
    }
    .carousel-item {
      height: 610px;
      .img-block {
        height: 580px;
        &:after {
          bottom: 20px;
        }
        &.last {
          background-position: 75% 0;
        }
      }
      .title-block {
        padding: 90px 0 0;
        display: block;
        text-align: center;
        .title {
          margin-bottom: 10px;

          font-size: 18px;
          font-weight: 900;
          line-height: normal;
        }
        .logo {
          width: auto;
          img {
            margin: 0 auto;
            display: block;
            width: auto;
            max-width: 50%;
          }
        }
      }
      .text-block {
        font-size: 14px;
        font-weight: normal;
        line-height: normal;
        .site-link {
          margin: 11px 0 0 30px;
          display: inline-block;
          vertical-align: top;
          font-size: 18px;
          text-transform: uppercase;
          font-weight: normal;
        }
      }
    }
    .carousel-indicators {
      bottom: 75px;
      li {
        width: 14px;
        height: 14px;
        background-color: gray;
        border-radius: 50%;
        &.active {
          background-color: $blue;
        }
      }
    }
  }
  .promo-main {
      flex-flow: row wrap;
      justify-content: flex-start;
      margin-bottom: 0;
      .left,.right {
        text-align: center;
        width: 100%;
        margin: 0;
      }
      .left .link {
        display: none;
      }
      .right {
        .btn-block {
          .btn {
            margin: 0 0 30px;
            color: $white;
            box-shadow: inset 0 0 0 3px #ededed;
            background-color: transparent;
            transition: all 250ms linear;
            &:hover {
              color: $white;
              box-shadow: none;
              background-color: #47b8ff;
            }
          }
          .btn-blue {
            display: none;
          }
        }
      }
      .text-block {
        margin-bottom: 20px;
        p {
          margin: 0;
          text-align: center;
          font-size: 12px;
          font-weight: normal;
          line-height: normal;
        }
        img {
          display: none;
        }
      }
      .title {
        margin-bottom: 17px;
        font-size: 18px;
        color: $white;
        line-height: 1.11;
      }
    }
  .presentation-block .presentation-list a {
    display: block;
    max-width: 100%;
    margin-right: 5px;
    .text {
      text-align: center;
      text-decoration: none;
      font-size: 18px;
      font-weight: 900;
      letter-spacing: 0.2px;
    }
  }
  .product-block {
    padding: 17px 0 30px;
    background: linear-gradient(180deg,#fafafa 55%,#ededed 0);
    .title {
      font-size: 22px;
      font-weight: 900;
      margin-bottom: 20px;
      text-transform: lowercase;
    }
    .product-list .product {
      margin-right: 10px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
  .map-wrap {
    padding-bottom: 30px;
    .title {
      font-size: 22px;
      font-weight: 900;
      margin-bottom: 20px;
    }
    .text {
      p {
        margin: 0;
        &:nth-child(1) {
          font-weight: 900;
          margin-bottom: 1px;
        }
        &:nth-child(2) {
          margin-bottom: 20px;
        }
      }
    }
    .map-list {
      margin-left: 0;
      font-weight: 500;
    }
    /*я
    .quote-block {
      margin-bottom: 20px;
      position: relative;
      left: inherit;
      bottom: inherit;
      .avatar {
        margin-top: 54px;
        width: 60px;
        height: 58px;
      }
      .quote {
        width: 260px;
        padding: 10px;
        font-size: 10px;
        border-radius: 17px;
        p {
          margin: 0;
          font-weight: normal;
          &:first-child {
            font-size: 10px;
            margin-bottom: 0;
          }
        }
      }
    }
    */
  }
  .presentation-block {
    padding-bottom: 0;
    .presentation-list {
      margin-bottom: 20px;
    }
    .text {
      font-size: 14px;
    }
  }
  .stack-wrap {
    padding-bottom: 30px;
    .stack-block {
      text-align: center;
    }
    .stack-list {
      .btn {
        margin: 0 0 8px;
        width: calc(50% - 6px);
      }
    }
    .direction-list {
      display: none;
    }
  }
  .sponsors-block {
    padding: 20px 0 40px;
    .title {
      font-size: 22px;
      font-weight: 900;
    }
  }
  footer {
    .logo {
      margin: 20px 0;
    }
    .copyright {
      top: inherit;
      left: inherit;
      bottom: 20px;
      right: 20px;
    }
    .menu {
      display: none;
    }
  }

  .services-wrap {
    padding: 20px 0 0;
    color: $black;
    .title {
      font-size: 18px;
      margin-bottom: 10px;
    }
    .directions-wrap {
      &:after {
        content: '';
        position: absolute;
        right: 0;
        top: 51px;
        width: 25px;
        height: 60px;
        background-image: linear-gradient(to right, rgba(255, 255, 255, 0), $white 53%, $white);
      }
      .title {
        margin-bottom: 16px;
        font-size: 16px;
      }
      .direction-list {
        margin-left: -10px;
        padding-bottom: 6px;
        display: flex;
        flex-flow: row nowrap;
        overflow-y: auto;
        .direction {
          margin: 0 0 0 10px;
          flex-shrink: 0;
          padding: 11px 19px 9px;
          font-size: 11px;
          line-height: normal;
          box-shadow: inset 0 0 0 2px #47b8ff;
        }
      }
    }
    .solutions-wrap {
      padding-top: 0;
      background-color: transparent;
      .title {
        margin-bottom: 20px;
        font-size: 16px;
      }
      .solutions-list {

      }
      .solution {
        padding-bottom: 40px;
        display: flex;
        flex-flow: row wrap;
        align-items: flex-start;
        .img-block {
          margin: 0 auto 10px;
          max-width: 100%;
          width: 100%;
          flex-shrink: inherit;
          @include aspect-ratio(100,71, 'inner-block');
        }
        .info-block {
          width: 100%;
          .title {
            font-size: 16px;
            font-weight: bold;
            color: #000;
            margin-bottom: 6px;
          }
          .description {
            margin-bottom: 20px;
            font-size: 14px;
            line-height: 20px;
            p {
              margin-top: 6px;
            }
          }
          .btn-block {
            text-align: center;
          }
          .btn {
            padding: 17px 25px;
            font-size: 18px;
            line-height: normal;
            height: 54px;
            border-radius: 30px;
          }
        }
      }
    }
  }

  .project-bg-block {
    height: 242px;
    .ellipse {
      top: -28px;
      right: -120px;
      background: url(../img/ellipse.png) 100% / 100% 100% no-repeat;
      width: 230px;
      height: 230px;
    }
    .phone {

    }
  }
  .project-top {
    margin-bottom: 50px;
    padding-top: 5px;
    display: block;
    .info {
      max-width: 100%;
      text-align: center;
      text-shadow: 0 0 14px rgba(0, 0, 0, 0.2);
    }
    .title {
      margin-bottom: 8px;
      font-size: 18px;
      font-weight: bold;
      color: $white;
      line-height: normal;
      text-transform: uppercase;
    }
    .text-block {
      font-size: 12px;
      p {
        margin-top: 0;
        line-height: normal;
      }
      .link {
        display: none;
      }
    }
    .img-block {
      flex-shrink: 0;
    }
    .link {
      color: #24272d;
      font-size: 18px;
      text-transform: uppercase;
    }
  }

  .project-main-info {
    margin-bottom: 30px;
    display: block;
    .title {
      font-size: 22px;
      font-weight: bold;
      padding-bottom: 20px;
    }
    .right {
      margin: 0;
      padding: 0;
      border: none;
      width: 100%;
      .title {
        font-size: 18px;
        padding-bottom: 10px;
      }
    }
    .text-block {
      font-size: 14px;
      p {
        margin-top: 0;
      }
    }
    .group-block {
      margin-bottom: 16px;
      .position {
        margin-bottom: 6px;
        font-size: 14px;
        font-weight: bold;
      }
      .name {
        margin-bottom: 19px;
        font-size: 12px;
      }
    }
  }

  .project-slider-wrap {
    .title {
      margin-bottom: 10px;
      font-size: 18px;
      color: #000000;
      font-weight: bold;
    }
    .carousel {
      margin-bottom: 30px;
    }
    .img-list {
      display: flex;
      img {
        display: block;
        width: 52%;
        margin-right: 3%;
        &:last-child {
          display: none;
          margin-right: 0;
        }
      }
    }
    .carousel-inner {
      width: 100%;
    }
    .carousel-indicators {
      display: flex;
      li {
        width: 10px;
        height: 10px;
        border-radius: 50%;
      }
    }
    .carousel-control-next, .carousel-control-prev {
      display: none;
    }
  }
}