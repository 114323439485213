.content {
  position: relative;
  z-index: 1;
}

.events-list {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  padding-bottom: 55px;
  .event {
    display: flex;
    margin-bottom: 30px;
    .avatar {
      margin-right: 30px;
      width: 64px;
      height: 64px;
      border-radius: 50%;
      box-shadow: inset 0 0 0 3px $blue;
      background-repeat: no-repeat;
      background-position: center center;
      background-color: #47b8ff;
      &.active {
        box-shadow: inset 0 0 0 3px $gray;
      }
    }
    .info {
      font-weight: bold;
      width: 182px;
    }
    .title {
      margin-bottom: 11px;
      font-size: 18px;
      font-style: italic;
      text-transform: uppercase;
      color: $black;
    }
    .text {
      font-size: 16px;
    }
  }
}

/*
.promo-main {
  padding-top: 40px;
  margin-bottom: 117px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  .left {
    margin-right: 20px;
  }
  .title {
    margin-bottom: 26px;
    font-size: 48px;
    line-height: 40px;
    font-weight: 900;
    color: $black;
    text-transform: uppercase;
  }
  .link {
    font-size: 18px;
    color: $gray;
    text-transform: uppercase;
    &:hover {
      text-decoration: none;
    }
  }
  .text-block {
    margin-bottom: 37px;
    text-align: right;
    color: $white;
    font-size: 18px;
    line-height: 21px;
    font-weight: 800;
    p {
      margin: 5px 0 15px;
    }
  }
  .btn-block {
    .btn {
      margin: 0 0 20px 20px;
      padding: 0;
      width: 230px;
      &.btn-blue {
        color: #24272d;
        background-color: $lightBlue;
        box-shadow: none;
        &:hover {
          color: $white;
        }
      }
    }
  }
}
*/

.product-block {
  margin-top: -56px;
  padding-bottom: 40px;
  background: linear-gradient(180deg, #fafafa 55%, #ededed 0);
  .title {
    margin-bottom: 17px;
    font-weight: bold;
    font-size: 16px;
    text-transform: uppercase;
  }
  .product-list {
    display: flex;
    justify-content: space-between;
    overflow-y: auto;
    .product {
      position: relative;
      flex-shrink: 0;
      width: calc(25% - 8px);
      min-width: 200px;
      overflow: hidden;
      border: 1px solid #24272d;
      background-color: #e7e7ec;
      box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.32);
      @include aspect-ratio(63, 87, 'inner-block');
      &.light {
        border-color: $blue;
      }
      &:hover {
        .product-info {
          transform: translate(0, 0);
        }
        .img-block {
          &:before {
            display: block !important;
          }
        }
      }
    }
    .product-info {
      position: absolute;
      left: 28px;
      right: 28px;
      bottom: 37px;
      color: #cedaf1;
      transform: translate(0, 100px);
      transition: transform 250ms linear;
      z-index: 1;
      img {
        margin: 0 auto 20px;
        display: block;
        width: auto;
        max-width: 100%;
      }
      .text {
        font-weight: bold;
        font-size: 18px;
        margin-bottom: 51px;
        cursor: text;
      }
      &.dark {
        color: $gray;
      }
    }
    .btn {
      display: block;
      width: 100%;
      color: $white;
      background-color: transparent;
      &:hover {
        color: $white;
        box-shadow: none;
        background-color: #47b8ff;
      }
    }
    .img-block {
      position: relative;
      background-color: #e7e7ec;
      img {
        display: block;
        width: 100%;
      }
      &:before {
        content: '';
        display: none;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-image: url("../img/product-list-grad.png");
        z-index: 1;
      }
    }
  }
}

.map-wrap {
  background-color: #ededed;
  padding-bottom: 28px;
  .title {
    margin-bottom: 17px;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 16px;
  }
  .map {
    margin-left: 21px;
    max-width: 99%;
  }
  .map-list {
    display: flex;
    overflow-y: auto;
    margin-left: 29px;
    a {
      margin-right: 17px;
      font-size: 16px;
      line-height: 18px;
      color: #555;
      flex-shrink: 0;
      &:hover {
        text-decoration: none;
      }
    }
  }
  /*
  .quote-block {
    position: absolute;
    left: 248px;
    bottom: 55px;
    display: flex;
    align-items: flex-start;
    .avatar {
      margin-top: 95px;
      width: 70px;
      height: 78px;
      background: url("../img/pavel_avatar.png") center / contain no-repeat;
    }
    .quote {
      margin-left: 3px;
      width: 320px;
      font-size: 14px;
      padding: 20px 25px;
      border-radius: 25px;
      box-shadow: inset 0 0 0 2px #000;
      p {
        margin: 0;
        font-weight: normal;
        &:first-child {
          font-size: 16px;
          margin-bottom: 10px;
        }
      }
    }
  }
  */
}

.presentation-block {
  padding-bottom: 60px;
  background: linear-gradient(#ededed 15%, #fff 0);
  .presentation-list {
    margin-bottom: 50px;
    display: flex;
    justify-content: space-between;
    font-family: 'Roboto', sans-serif;
    overflow-y: auto;
    a {
      position: relative;
      display: block;
      margin-right: 5px;
      flex-shrink: 0;
      text-decoration: none;
      &:last-child {
        margin-right: 0;
      }
      img {
        display: block;
        max-width: 100%;
      }
      .text {
        width: 315px;
        font-size: 24px;
        color: #cedaf1;
        font-weight: 900;
        text-transform: uppercase;
        text-align: center;
        line-height: 1.5em;
        text-decoration: underline;
        @include position-centered();
      }
      &:hover {
        .text {
          text-decoration: none;
        }
      }
    }
  }
  .text {
    font-size: 16px;
    color: #373737;
  }
}

.stack-wrap {
  padding-bottom: 75px;
  display: flex;
  justify-content: space-between;
  flex-flow: row wrap;
  .stack-block {
    flex-grow: 1;
  }
  .stack-list {
    margin-left: -10px;
    .btn {
      margin: 0 0 12px 10px;
      width: 180px;
      text-align: center;
      cursor: default;
    }
  }
  .last {
    margin-top: 16px;
  }
  .direction-list {
    padding: 0;
    margin: 10px 0 0;
    list-style: none;
    text-align: right;
    flex-shrink: 0;
    a {
      color: #373737;
      font-size: 18px;
      line-height: 24px;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.sponsors-block {
  padding: 36px 0 60px;
  background-color: #47b8ff;
  .title {
    margin-bottom: 27px;
    font-weight: bold;
    font-size: 16px;
    text-transform: uppercase;
    color: $white;
  }
  .sponsors-list {
    margin-left: -20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    overflow-y: auto;
    a {
      margin-left: 20px;
      text-decoration: none;
    }
    img {
      display: block;
    }
  }
}

//*{
//  border: 1px solid red;
//}