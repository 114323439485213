@mixin text-overflow($width: inherit) {
  max-width: $width;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin flex($flow: nowrap, $justify: flex-start, $align: stretch) {
  display: flex;
  flex-flow: row $flow;
  justify-content: $justify;
  align-items: $align;
}

@mixin aspect-ratio($width, $height, $name) {
  position: relative;
  &:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: ($height / $width) * 100%;
  }
  > .#{$name} {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

@mixin position-centered() {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}