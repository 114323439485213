footer {
  margin-top: auto;
  position: relative;
  height: 82px;
  background-color: #202837;
  .logo {
    display: inline-block;
    margin: 14px 0 25px;
    text-decoration: none;
    img {
      display: inline-block;
      vertical-align: top;
      width: 40px;
    }
  }
  .copyright {
    position: absolute;
    top: 35px;
    left: 85px;
    color: #cedaf1;
    font-size: 16px;
    cursor: default;
  }
  .menu {
    position: absolute;
    top: 30px;
    right: 14px;
    display: flex;
    justify-content: flex-end;
    a {
      margin-left: 20px;
      text-decoration: none;
      color: #404e6c;
      font-size: 18px;
      &:hover {
        color: $white;
      }
    }
  }
}

