* {
  box-sizing: border-box;
}
body {
  font-family: 'Roboto', sans-serif;
  background-color: #fff;
}

.desktop {
  display: block;
}
.mobile {
  display: none;
}

@media (max-width: 820px) {
  .desktop {
    display: none;
  }
  .mobile {
    display: block;
  }
}
.hidden {
  display: none !important;
}

.btn {
  display: inline-block;
  vertical-align: top;
  padding: 0 26px;
  color: $gray;
  height: 46px;
  font-size: 18px;
  line-height: 46px;
  text-align: center;
  border-radius: 23px;
  box-shadow: inset 0 0 0 3px #24272d;
  text-decoration: none;
  &.btn-transparent {
    color: #373737;
    box-shadow: inset 0 0 0 3px #ededed;
    background-color: $white;
    transition: all 250ms linear;
    &:hover {
      color: $white;
      box-shadow: none;
      background-color: #47b8ff;
    }
  }
  &.btn-blue {
    color: $white;
    box-shadow: none;
    background-color: #47b8ff;
    transition: all 250ms linear;
    &:hover {
      color: #373737;
      box-shadow: inset 0 0 0 3px #ededed;
      background-color: $white;
    }
  }
}