.about-top {
  margin-bottom: 275px;
  padding-top: 72px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  .info {
    max-width: 556px;
    flex-grow: 1;
  }  
}